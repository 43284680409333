import { FC, useContext, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { ModalContext } from 'components';
import { StreamOnlineIcon } from 'icons';
import { Button, Typography } from 'ui-kit';

import { UsersIcon } from '../../../../../icons/UsersIcon';
import { UsersOnlineIcon } from '../../../../../icons/UsersOnlineIcon';
import { HorizontalDivider } from '../../../../../ui-kit/Dividers/HorizontalDivider';
import { useStyle } from './VideoBlock.style';

type TStreamer = {
  name: string;
  avatar: string;
  url: string;
  isOnline: boolean;
};

const ONLINE_COLOR = '#03D7BD';

const nonLoginBanners = [
  'url(images/main-page/video-block/banner.jpg)',
  'url(images/main-page/video-block/IMG_20241108_215815_195.png)',
];
const nonLoginTitles = ['home.videoBlock.title', ''];
const nonLoginDescs = ['home.videoBlock.description', ''];

const banners = [
  'url(images/main-page/video-block/IMG_20241108_215815_195.png)',
  'url(images/main-page/video-block/banner-invoker.png)',
  'url(images/main-page/video-block/banner-leoric.png)',
];
const titles = [
  '',
  'home.videoBlock.innerTitle',
  'home.videoBlock.innerTitleSecondary',
];

const descs = ['', 'home.videoBlock.innerDescription', ''];

const streamers: Array<TStreamer> = [
  {
    name: 'Meepoplayer',
    avatar: '/images/home/streamers/Meepoplayer.jpg',
    url: 'https://www.twitch.tv/meepoplayer',
    isOnline: true,
  },
  {
    name: 'Canceldota',
    avatar: '/images/home/streamers/Canceldota.jpg',
    url: 'https://www.twitch.tv/canceldota',
    isOnline: true,
  },
  {
    name: 'Finargot',
    avatar: '/images/home/streamers/Finargot.jpg',
    url: 'https://www.twitch.tv/finargot',
    isOnline: false,
  },
  {
    name: 'Nedlowpeek',
    avatar: '/images/home/streamers/Nedlowpeek.jpg',
    url: 'https://www.twitch.tv/nedlowpeek',
    isOnline: false,
  },
  {
    name: 'Chillfam1ly',
    avatar: '/images/home/streamers/Chillfam1ly.jpg',
    url: 'https://www.twitch.tv/Chillfam1ly',
    isOnline: false,
  },
];

export const VideoBlock: FC = () => {
  const { classes, cx } = useStyle();
  const { t: translation } = useTranslation();
  const { data: user } = useSWR('api/user');
  const isLogin = user?.id;

  const {
    loginModal: { openLogin },
    battlePassAds: { onOpenBattlePassAds },
  } = useContext(ModalContext);
  const { Headline, Text } = Typography;

  const [imageIndex, setImageIndex] = useState(0);
  const currentBanner = useMemo(
    () => (isLogin ? banners : nonLoginBanners),
    [isLogin]
  );
  const currentTitles = useMemo(
    () => (isLogin ? titles : nonLoginTitles),
    [isLogin]
  );
  const currentDescs = useMemo(
    () => (isLogin ? descs : nonLoginDescs),
    [isLogin]
  );
  useEffect(() => {
    const intervalId = setInterval(
      () =>
        setImageIndex((prev) => {
          return prev < currentBanner.length - 1 ? prev + 1 : 0;
        }),
      5000
    );
    return () => clearInterval(intervalId);
  }, [currentBanner]);

  const buttons = useMemo(
    () => [
      {
        text: 'home.videoBlock.btnMore',
        onClick: () => onOpenBattlePassAds(),
      },
      {
        text: 'home.videoBlock.btnMore',
        onClick: () => (window.location.href = '/challenges'),
      },
      {
        text: 'home.videoBlock.innerBtn',
        onClick: () => (window.location.href = '/challenges'),
      },
    ],
    []
  );

  const nonButtons = useMemo(
    () => [
      { text: 'home.videoBlock.btn', onClick: () => openLogin() },
      { text: 'home.videoBlock.btnMore', onClick: () => onOpenBattlePassAds() },
    ],
    []
  );

  return (
    <div className={classes.root}>
      <div className={classes.community}>
        <div className={classes.streamersContainer}>
          <Headline variant="h2">
            {translation('home.videoBlock.community.ourStreamers')}
          </Headline>
          <HorizontalDivider className={classes.divier} />
          <ul className={classes.streamers}>
            {streamers.map(({ avatar, name, url, isOnline }) => (
              <li key={name}>
                <div className={classes.streamerInfo}>
                  <div className={classes.avatarWrapper}>
                    <img
                      className={classes.streamerAvatar}
                      src={avatar}
                      alt={name}
                    />
                  </div>
                  <a href={url}>
                    <Text variant="b2" decoration="d1" color="silver">
                      {name}
                    </Text>
                  </a>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div
        className={classes.videoContainer}
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {/* <ReactPlayer
          width={'100%'}
          height={'100%'}
          url="https://www.youtube.com/watch?v=rGOQncWs1oc"
        /> */}
        <img
          className={classes.slardarImg}
          src="images/main-page/video-block/slardar.png"
          alt=""
        />
        <div className={classes.content}>
          <Headline block className={classes.contentItem}>
            {translation('home.videoBlock.howUsePlatform')}
          </Headline>
          <ul className={classes.contentList}>
            <li className={classes.contentItem}>
              <Text>{translation('home.videoBlock.step1.before')}</Text>
              <Text color="greenLight">
                {translation('home.videoBlock.step1.greenText')}
              </Text>
              <Text>{translation('home.videoBlock.step1.after')}</Text>
            </li>
            <li className={classes.contentItem}>
              <Text>{translation('home.videoBlock.step2.before')}</Text>
              <Text color="greenLight">
                {translation('home.videoBlock.step2.greenText')}
              </Text>
              <Text>{translation('home.videoBlock.step2.after')}</Text>
            </li>
            <li className={classes.contentItem}>
              <Text>{translation('home.videoBlock.step3.before')}</Text>
              <Text color="greenLight">
                {translation('home.videoBlock.step3.greenText')}
              </Text>
              <Text>{translation('home.videoBlock.step3.after')}</Text>
            </li>
          </ul>
        </div>
      </div>
      <div
        key={imageIndex}
        className={cx(
          classes.banner,
          isLogin && cx(classes.banner, classes.innerBanner)
        )}
        style={{
          backgroundImage: currentBanner[imageIndex],
        }}
      >
        <div>
          <Headline variant="h2">
            {translation(currentTitles[imageIndex])}
          </Headline>
          <Text className={classes.description} variant="b3">
            {translation(currentDescs[imageIndex])}
          </Text>
        </div>
        {isLogin ? (
          // <a
          //   href="https://gamegreed.gitbook.io/gamegreed"
          //   target="_blank"
          //   rel="noreferrer"
          // >
          <Button
            color="brand"
            buttonSize="l"
            style={{
              width: '100%',
            }}
            onClick={buttons[imageIndex].onClick}
          >
            {translation(buttons[imageIndex].text)}
          </Button>
        ) : (
          // </a>
          <Button
            color="brand"
            buttonSize="l"
            onClick={nonButtons[imageIndex].onClick}
          >
            {translation(nonButtons[imageIndex].text)}
          </Button>
        )}
      </div>
    </div>
  );
};
