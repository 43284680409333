import { useTranslation } from 'react-i18next';

import { Button, Input } from '../../../../ui-kit';
import { Headline } from '../../../../ui-kit/Typography/Headline';
import { useStyle } from './PromoCodeSteam.style';

export const PromoCodeSteam = () => {
  const { classes } = useStyle();
  const { t: translation } = useTranslation();

  return (
    <div className={classes.promoCodeSteamContainer}>
      <Headline variant="h4">
        Введи промокод, чтобы вывести средства на Steam-кошелек
      </Headline>
      <div className={classes.inputContainer}>
        <Input placeholder={translation('profile.promo.placeholder')} />
        <Button>Применить</Button>
      </div>
    </div>
  );
};
