import { FC } from 'react';

import { ICommonIconProps } from './types';

export const GoArrowIcon: FC<ICommonIconProps> = ({
  width = 39,
  height = 16,
  viewBox = '0 0 39 16',
  className = '',
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M38.2071 8.70711C38.5976 8.31658 38.5976 7.68342 38.2071 7.29289L31.8431 0.928932C31.4526 0.538408 30.8195 0.538408 30.4289 0.928932C30.0384 1.31946 30.0384 1.95262 30.4289 2.34315L36.0858 8L30.4289 13.6569C30.0384 14.0474 30.0384 14.6805 30.4289 15.0711C30.8195 15.4616 31.4526 15.4616 31.8431 15.0711L38.2071 8.70711ZM0.5 9H37.5V7H0.5V9Z"
      fill="#0A0D10"
    />
  </svg>
);
