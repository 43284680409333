import React, { useRef } from 'react';

import { useClickOutside } from '../../../../hooks';
import { CloseIcon } from '../../../../icons';
import { Headline } from '../../../../ui-kit/Typography/Headline';
import { Modal } from '../Modal';
import { useStyle } from './BalanceActionConfirmModal.style';

interface IBalanceActionConfirmModal {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  content: React.ReactNode;
}

const ICON_CLOSE_COLOR = '#8188A2';

export const BalanceActionConfirmModal: React.FC<
  IBalanceActionConfirmModal
> = ({ isOpen = false, onClose, title, content }) => {
  const { classes } = useStyle();
  const ref = useRef();

  useClickOutside(ref, onClose);

  return (
    <Modal isOpen={isOpen}>
      <div ref={ref} className={classes.modal}>
        <div className={classes.header}>
          <Headline variant="h3" className={classes.title}>
            {title}
          </Headline>
          <button onClick={onClose} className={classes.close}>
            <CloseIcon color={ICON_CLOSE_COLOR} />
          </button>
        </div>
        {content}
      </div>
    </Modal>
  );
};
