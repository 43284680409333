import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  modal: {
    minWidth: 350,
    minHeight: 200,
    padding: 24,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  close: {},
  title: {},
  contentContainer: {
    textAlign: 'center',
    padding: '30px 100px',
    backgroundColor: '#202333',
  },
}));
