import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { useClickOutside } from '../../../../hooks';
import { CloseIcon } from '../../../../icons';
import { GoArrowIcon } from '../../../../icons/GoArrowIcon';
import { Button, Typography } from '../../../../ui-kit';
import { Modal } from '../Modal';
import { useStyle } from './BattlePassAdsModal.style';

interface IBattlePassAdsModal {
  onClose: () => void;
  isOpen: boolean;
}

export const BattlePassAdsModal: React.FC<IBattlePassAdsModal> = ({
  isOpen,
  onClose,
}) => {
  const ref = useRef(null);
  const { classes } = useStyle();
  const { t: translation } = useTranslation();

  useClickOutside(ref, onClose);

  const { Headline, Text } = Typography;

  return (
    <Modal isOpen={isOpen}>
      <div ref={ref} className={classes.modal}>
        <div className={classes.header}>
          <button onClick={onClose} className={classes.close}>
            <CloseIcon color="#fff" />
          </button>
          <Headline className={classes.title}>
            {translation('modal.battlePassAds.title')}
          </Headline>
        </div>
        <div className={classes.contentContainer}>
          <Text block variant="b5">
            {translation('modal.battlePassAds.content.before')}{' '}
            <Text
              variant="b4"
              color="greenLight"
              className={classes.battlePassText}
            >
              Battle Pass
            </Text>
            . {translation('modal.battlePassAds.content.after')}
          </Text>
          <a href="https://battlepass.ru/greed" target="blank">
            <Button className={classes.battlePassAdsBtn} color="brand">
              {translation('modal.battlePassAds.goToBattlePass')}{' '}
              <GoArrowIcon className={classes.arrowRight} />
            </Button>
          </a>
        </div>
      </div>
    </Modal>
  );
};
