import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  depositContainer: {
    backgroundColor: theme.palette.bg.ghost,
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 32px',
    borderRadius: 8,
    marginBottom: '16px',
  },
  form: {
    backgroundColor: '#2B3353',
    padding: '16px 20px',
    borderRadius: 6,
  },
  title: {
    marginTop: '10px',
  },
  supportText: { marginTop: 20, marginBottom: 34 },
  textWarning: {
    textAlign: 'center',
    margin: '20px 0',
  },
  inputContainer: {
    display: 'flex',
    gap: 22,
    alignItems: 'center',
  },
  input: {
    width: '100%',
  },
  bottomContainer: {
    marginTop: 12,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  taxContainer: {
    display: 'flex',
    gap: 16,
  },
  taxDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  buttonContainer: {
    display: 'flex',
    gap: 16,
    marginRight: 0,
    marginLeft: 'auto',
  },
  buyBtn: {
    minWidth: 160,
  },
  paymentDescription: {
    width: 'max-content',
  },
  paymentTexts: {
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '6px',
    marginLeft: '10px',
    marginRight: '10px',
  },
  paymentImageContainer: {
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: '1px solid #8188A2',
  },
  paymentButtom: {
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid #8188A2',
    borderRadius: '6px',
    cursor: 'pointer',
  },
  paymentButtoms: {
    display: 'flex',
    gap: '20px',
    marginTop: 10,
    marginBottom: 30,
  },
  activeBorder: {
    borderColor: '#2D63FF',
  },
  confirmContentAmount: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.bg.ghost,
    padding: '20px 16px',
    borderRadius: 8,
    margin: '20px 0 30px 0',
    gap: 30,
  },
  confirmContentTax: {
    marginBottom: 20,
    '&>div': {
      display: 'flex',
      justifyContent: 'space-between',
      '&:first-child': {
        marginBottom: 10,
      },
    },
  },
  confirmContentFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
}));
