import { makeStyles } from 'theme';

export const useStyle = makeStyles()(() => ({
  bannerContainer: {
    backgroundImage: "url('/images/wallet/discordBanner.jpg')",
    borderRadius: 8,
    height: 208,
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  btn: {
    width: 'fit-content',
  },
}));
