import { makeStyles } from 'theme';

export const useStyle = makeStyles()(() => ({
  walletCard: {
    display: 'flex',
    gap: 20,
    justifyContent: 'flex-end',
    flexDirection: 'column',
    width: 240,
    '& > button': {
      width: '100%',
      padding: 0,
      '& > span': {
        fontSize: 18,
      },
    },
  },
  balanceContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    marginLeft: 0,
    marginRight: 'auto',
  },
}));
