import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  promoCodeSteamContainer: {
    backgroundColor: theme.palette.bg.ghost,
    padding: '16px 32px',
    borderRadius: 8,
    marginBottom: '16px',
  },
  inputContainer: {
    display: 'flex',
    columnGap: 15,
    marginTop: 20,

    '& > div': {
      flex: 1,
    },
  },
}));
