import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  modal: {
    overflow: 'auto',
    width: 900,
    height: '95vh',
  },
  header: {
    position: 'relative',
    backgroundImage: 'url("/images/modals/battlepassads-bg.png")',
    height: 400,
  },
  close: {
    position: 'absolute',
    top: 30,
    right: 30,
  },
  title: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  contentContainer: {
    textAlign: 'center',
    padding: '30px 90px',
    backgroundColor: '#202333',
    height: 'calc(100% - 400px)',
  },
  battlePassAdsBtn: {
    margin: '60px 0',
    padding: '15px 50px !important',
    height: 'auto !important',
    '&::after': {
      opacity: '1 !important',
    },
    '& > span': {
      color: '#000',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  battlePassText: {
    textDecoration: 'underline',
  },
  arrowRight: {
    marginLeft: 20,
  },
}));
