import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { Button, Card, Typography } from 'ui-kit';

import { useStyle } from './Wallet.style';

interface IWallet {
  balanceAction: { isManualDepositOpen: boolean; isWithdrawFiatOpen: boolean };
  onOpenManualDeposit: () => void;
  onOpenWithdrawFiat: () => void;
}

export const Wallet: FC<IWallet> = ({
  balanceAction,
  onOpenManualDeposit,
  onOpenWithdrawFiat,
}) => {
  const { classes } = useStyle();
  const { data: balance } = useSWR('api/balance');
  const { Headline, Text } = Typography;
  // const {
  //   manualDeposit: { openManualDeposit },
  //   withdrawFiat: { openWithdrawFiat },
  // } = useContext(ModalContext);

  const { isManualDepositOpen, isWithdrawFiatOpen } = balanceAction;

  const { t: translation } = useTranslation();

  return (
    <Card className={classes.walletCard} variant="p12x16" color="bgGhost">
      <div className={classes.balanceContainer}>
        <Text variant="b4" color="typoLabel" block>
          {translation('profile.header.balance')}
        </Text>
        <Headline variant="h2" block>
          {`${balance?.balance} GC`}
        </Headline>
        <Text variant="b6" color="typoPlaceholder" block>
          {`≈ ${(balance?.balance / 10).toFixed(1)} Руб.`}
        </Text>
      </div>
      {/* {canWithdraw ? ( */}
      <Button
        disabled={isManualDepositOpen}
        color={isManualDepositOpen ? 'secondary' : undefined}
        buttonSize="xl"
        onClick={onOpenManualDeposit}
      >
        {translation('profile.header.deposit')}
      </Button>

      {/* ) : 
        {/* <Tooltip
          content={translation('profile.header.tooltip')}
          position="bottom"
        >
          <Button buttonSize="xl" color="secondary" disabled={true}>
            {translation('profile.header.withdraw')}
          </Button>
        </Tooltip>
      )} */}
      <Button
        disabled={isWithdrawFiatOpen}
        color={isWithdrawFiatOpen ? 'secondary' : undefined}
        buttonSize="xl"
        onClick={onOpenWithdrawFiat}
      >
        {translation('profile.header.withdraw')}
      </Button>
      <Text variant="b4">
        Средства не доступны для вывода. Сделай 3 предикта, чтобы такая
        возможность появилась
      </Text>
    </Card>
  );
};
